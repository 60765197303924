<template>
  <div>
    <div class="space-y-2 py-4" :class="{ 'quick-start-selected': $store.state.quickStart.step === 4 }">
      <div class="w-full flex justify-between">
        <span class="font-bold text-md">Productos</span>
        <a class="text-purple-400" @click="showCreateProductDialog">
          <strong>+ Agregar nuevo producto</strong>
        </a>
      </div>
      <div>
        <div class="flex flex-row space-x-2">
          <el-input
            type="text"
            v-model="productTextSearch"
            @input="showProductsDialog(true)">
            <i slot="prefix" class='mt-2 text-xl bx bx-search' />
          </el-input>
          <sa-button
            size="mini"
            type="secondary"
            @click="showProductsDialog(true)">
            Explorar
          </sa-button>
        </div>
      </div>
    </div>

    <table
      class="table-fixed py-4 border-separate"
      v-if="hasOrderDeviceProducts">
      <thead>
      <tr class="text-xs text-gray-400 uppercase">
        <th class="w-5/12 text-left">
          <span>Producto</span>
        </th>
        <th class="w-2/12 text-left">
          <span>Cantidad</span>
        </th>
        <th class="w-2/12 text-right">
          <span class="mr-2">Costo</span>
        </th>
        <th class="w-2/12 text-right">
          <span class="mr-2">Precio</span>
        </th>
        <th class="w-1/12 sr-only">
          Eliminar
        </th>
      </tr>
      </thead>
      <tbody>
      <tr
        v-for="(product, index) in orderDevice.orderDeviceProducts"
        :key="`orderDeviceProducts-${index}`">
        <td class="">
          <div class="w-full flex flex-col">
            <strong class="text-sm">{{ product.productCategory?.name || product.category }}</strong>
            <span>{{ product.name }}</span>
          </div>
        </td>
        <td>
          <el-form
            :ref="`product-${index}-quantity`"
            :model="product"
            :rules="productRules">
            <el-form-item prop="quantity">
              <el-input
                v-model="product.quantity"
                type="number"
                placeholder="0" />
            </el-form-item>
          </el-form>
        </td>
        <td>
          <el-form
            :ref="`product-${index}-cost`"
            :model="product"
            :rules="productRules">
            <el-form-item prop="cost">
              <el-input
                v-if="$store.state.account.canEditOrdersPrices"
                v-model="product.cost"
                type="number"
                placeholder="0.00" />
              <el-input
                disabled
                v-if="!$store.state.account.canEditOrdersPrices"
                placeholder="***" />
            </el-form-item>
          </el-form>
        </td>
        <td>
          <el-input
            v-model="product.price"
            class="w-full el-input-text-right"
            type="number"
            placeholder="0.00" />
        </td>
        <td class="text-right">
          <sa-icon-button
            iconClass="bx bx-x"
            with-bg
            @click="removeProduct(index)" />
        </td>
      </tr>
      </tbody>
    </table>

    <el-dialog
      :visible.sync="isVisibleSearchProductsDialog"
      :width="dialogClientWidth"
      @close="onCloseSearchProductsDialog">
      <div class="-mx-5">
        <device-products-search
          v-if="isVisibleSearchProductsDialog"
          :textSearch="productTextSearch"
          @cancel="showProductsDialog(false)"
          @onSelectProducts="onSelectProducts"
        />
      </div>
    </el-dialog>
    <el-dialog
      :visible.sync="isVisibleCreateProductDialog"
      append-to-body
      show-close
      :width="dialogClientWidth"
      @close="closeCreateProductDialog">
      <div class="w-full flex justify-between">
        <span class="font-bold text-md">Crear Producto</span>
        <el-button class="el-button--primary" @click="createProduct">Guardar</el-button>
      </div>
      <product-form :loading="createProductLoading" :reset="isVisibleCreateProductDialog" :form="createProductForm" />
    </el-dialog>
  </div>
</template>
<style scoped>
.el-form-item {
  margin-bottom: 0;
}
.is-error {
  margin-bottom: 32px;
}
</style>
<script>
import { state, mutations } from '../store';
import responsiveSize from '@/mixins/responsiveSize';
import ProductService from '@/services/ProductService';
import error from '@/mixins/error';

import _ from "lodash";

export default {
  name: 'ProductsBlock',
  components: {
    DeviceProductsSearch: () => import('./ProductsSearch.vue'),
    ProductForm: () => import('@/views/app/products/Form.vue'),
  },
  mixins: [
    responsiveSize,
    error,
  ],
  props: {
    orderDeviceIndex: {
      type: Number,
      default: () => null,
    },
  },
  data() {
    return {
      dialogClientWidth: '40%',
      productTextSearch: null,
      isVisibleSearchProductsDialog: false,
      isVisibleCreateProductDialog: false,
      createProductLoading: false,
      createProductForm: {
        name: undefined,
        description: undefined,
        category: undefined,
        brand_model: undefined,
        cost: undefined,
        price: undefined,
        quantity: 0,
        minStockAlert: null,
        maxStockAlert: null,
      },
      productRules: {
        quantity: [{
          required: true,
          validator(rule, value, callback) {
            if (value === null || value === undefined || value === '' || value <=0)
              callback(new Error('La cantidad debe ser mayor a 0 para continuar'));
          },
        }],
        cost: [{
          required: true,
          validator(rule, value, callback) {
            if (value === null || value === undefined || value === '' || value < 0)
              callback(new Error('El costo debe ser 0 o un número mayor para continuar'));
          },
        }],
      },
    };
  },
  created() {
    window.addEventListener("resize", _.debounce(this.resizeDialogWidth, 200));
  },
  destroyed() {
    window.removeEventListener("resize", _.debounce(this.resizeDialogWidth, 200));
  },
  computed: {
    orderDevice() {
      return state.order.orderDevices[this.orderDeviceIndex];
    },
    hasOrderDeviceProducts() {
      if (!this.orderDevice.orderDeviceProducts) {
        return false;
      }
      return this.orderDevice.orderDeviceProducts.length > 0;
    },
  },
  watch: {
    'orderDevice.orderDeviceProducts': {
      deep: true,
      handler: _.debounce(async function () {
        const refs = Object.keys(this.$refs);
        for (let i = 0; i < refs.length; i++) {
          const form = this.$refs[refs[i]][0];
          if (form) {
            form.validate().catch(() => {});
          }
        }
      }, 300),
    },
  },
  methods: {
    removeProduct(orderDeviceProductIndex) {
      mutations.removeOrderDeviceProduct(this.orderDeviceIndex, orderDeviceProductIndex)
    },
    onCloseSearchProductsDialog() {
      this.productTextSearch = null;
    },
    showProductsDialog(show) {
      this.isVisibleSearchProductsDialog = show;
    },
    showCreateProductDialog() {
      this.isVisibleCreateProductDialog = true;
      this.resizeDialogWidth();
    },
    closeCreateProductDialog() {
      this.isVisibleCreateProductDialog = false;
      this.createProductForm = {
        name: '',
        description: '',
        category: null,
        brand_model: '',
        cost: null,
        price: null,
        quantity: null,
        image: '',
        minStockAlert: null,
        maxStockAlert: null,
      };
    },
    onSelectProducts(selectedProducts) {
      for (let i = 0; i < selectedProducts.length; i++) {
        const product = selectedProducts[i];
        mutations.addOrderDeviceProduct(this.orderDeviceIndex, { ...product, quantity: 1 });
      }
      this.showProductsDialog(false);
    },
    resizeDialogWidth() {
      const percentageWidth = this.getPercentageWidth();
      this.dialogClientWidth = percentageWidth === '30%' ? '40%' : percentageWidth;
    },
    async createProduct() {
      this.createProductLoading = true;
      try {
        const request = {
          name: this.createProductForm.name,
          description: this.createProductForm.description,
          category: this.createProductForm.category,
          brandModel: this.createProductForm.brand_model,
          cost: parseFloat(this.createProductForm.cost),
          price: parseFloat(this.createProductForm.price),
          quantity: parseFloat(this.createProductForm.quantity),
          minStockAlert: parseFloat(this.createProductForm.minStockAlert),
          maxStockAlert: parseFloat(this.createProductForm.maxStockAlert),
        };
        const product = await ProductService.create({ product: request });
        await this.$toastr.s('Se creo con exito');
        mutations.addOrderDeviceProduct(this.orderDeviceIndex, { ...product, quantity: 1 });
        this.closeCreateProductDialog();
      } catch(error) {
        this.$toastr.e(this.getErrorMessage(error));
      } finally {
        this.createProductLoading = false;
      }
    },
  },
};
</script>
